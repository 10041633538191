import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { AutoLogoutProvider } from "./components/AutoLogoutProvider";
import Login from "./pages/Login/Login";

import "./index.css";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserProvider } from "./context/UserContext";
import ThemeContextProvider from "./context/ThemeContext";
import { CookiesProvider } from "react-cookie";
import { CMSWrapper } from "./components/CMS/CMSWrapper";
import ParametersProvider from "./context/ParametersContext";
import DevicesProvider from "./context/DevicesContext";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { PageNotFound } from "./pages/ReRoute/PageNotFound";
import AlarmsProvider from "./context/AlarmsContext";
import { ToastContainer } from "react-toastify";
import { MainScreen } from "./components/CMS/MainScreen";
import { Profile } from "./pages/Profile/Profile";
import { ForgotPassword } from "./pages/Login/ForgotPassword";
import { UserManagement } from "./pages/UserMgmt/UserManagement";
import { AccessManagement } from "./pages/AccessManagement/AccessManagement";
import { DeviceManagement } from "./pages/DeviceManagement/DeviceManagement";
import { ServerManagement } from "./pages/SystemManagement/ServerManagement";
import { ResetPassword } from "./pages/Login/ResetPassword";
import DeviceSocketProvider from "./context/DeviceSocketContext";
import { WaveMonitor } from "./pages/WaveMonitor";
import { CareSummary } from "./pages/CareSummary";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CacheBuster from "react-cache-buster";
import { SysAdmin } from "./pages/SysAdmin/SysAdmin";
import MonitorGroupManager from "./pages/MonitorGroups/MonitorGroupManager"
i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "de", "fr", "no", "ar", "ro", "it", "es", "ca"],
    fallbackLng: "en",
    debug: false,
    // Options for language detector
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

  const App = () => {
    const isProduction = process.env.NODE_ENV === "production";
    const buildNumber = process.env.REACT_APP_BUILD_NUMBER || "0.0.0";
    console.log("isProduction:", isProduction);
  
    return (
      <CacheBuster
        currentVersion={buildNumber}
        isEnabled={true} // If false, the library is disabled.
        isVerboseMode={false} // If true, the library writes verbose logs to console.
        metaFileDirectory={"."} // If public assets are hosted somewhere other than root on your server.
      >
        <DndProvider backend={HTML5Backend}>
          <CookiesProvider>
            <ThemeContextProvider>
              <UserProvider>
                <AutoLogoutProvider> {/* AutoLogout logic persists across pages */}
                  < DevicesProvider>
                    <ParametersProvider>
                      <AlarmsProvider>
                        <ToastContainer
                          position="top-right"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop
                          closeOnClick
                          rtl={false}
                          draggable
                          pauseOnHover
                          pauseOnFocusLoss={false}
                        />
                        <DeviceSocketProvider>
                          <Router basename={process.env.PUBLIC_URL + "/"}>
                            <CMSWrapper>
                              <Switch>
                                <Route exact path="/login" component={Login} />
                                <ProtectedRoute
                                  exact
                                  path="/profile"
                                  component={Profile}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/wavemonitor/:deviceId"
                                  component={WaveMonitor}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/caresummary/:deviceId"
                                  component={CareSummary}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/usermgmt"
                                  component={UserManagement}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/devicemgmt"
                                  component={DeviceManagement}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/accessmgmt"
                                  component={AccessManagement}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/vicumgmt"
                                  component={MonitorGroupManager}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/sysmgmt"
                                  component={ServerManagement}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/sysadmin"
                                  component={SysAdmin}
                                />
                                <Route
                                  exact
                                  path="/forgot-password"
                                  component={ForgotPassword}
                                />
                                <Route
                                  exact
                                  path="/password-reset/:token"
                                  component={ResetPassword}
                                />
                                <ProtectedRoute
                                  exact
                                  path="/"
                                  component={MainScreen}
                                />
                                <Route path="*" component={PageNotFound} />
                              </Switch>
                            </CMSWrapper>
                          </Router>
                        </DeviceSocketProvider>
                      </AlarmsProvider>
                    </ParametersProvider>
                  </DevicesProvider>
                </AutoLogoutProvider>
              </UserProvider>
            </ThemeContextProvider>
          </CookiesProvider>
        </DndProvider>
      </CacheBuster>
    );
  };
  
  

const loadingMarkup = (
  <div>
    <h3>Loading..</h3>
  </div>
);

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
