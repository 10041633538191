import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from "react-i18next";
import CustomTabs from "../components/tabs/CustomTabs";
import { Event } from "../pages/WaveMonitor";
import EventTab from './EventTab';
import { VideoTab } from './VideoTab';
import { MapTab } from './MapTab';
import { Device } from '../interfaces/Device';

interface Props {
    alarmList: Event[],
    eventList: Event[],
    ecgList: Event[],
    msgList: Event[],
    imageList: Event[],
    videoId: string;
    onEventSelected: (event: Event) => void;
    style?: React.CSSProperties;
    device: Device;
    showLive?: boolean;
}

export const EventTable: React.FC<Props> = ({ device, videoId, eventList, ecgList, msgList, imageList, onEventSelected, showLive = true }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);
    //console.log("EventTable:", showLive);

    const tabNames = [
        t("Events"),
        t("ECG"),
        t("Msg"),
        t("Images"),
        ...(videoId && showLive ? [t("Video")] : []),
        ...(showLive ? [t("Map")] : [])
    ];

    const tabComponents = [
        <EventTab events={eventList} onEventSelected={onEventSelected} />,
        <EventTab events={ecgList} onEventSelected={onEventSelected} />,
        <EventTab events={msgList} onEventSelected={onEventSelected} />,
        <EventTab events={imageList} onEventSelected={onEventSelected} />,
        ...(videoId && showLive ? [<VideoTab stream={videoId} />] : []),
        ...(showLive ? [<MapTab device={device} />] : [])
    ];

    // Ensure the activeTab is always valid before rendering
    //const validatedActiveTab = Math.min(activeTab, tabNames.length - 1);
    const validatedActiveTab = activeTab > tabNames.length-1 ? 0 : activeTab;
    return (
        <Box sx={{ width: '100%' }}>
            <CustomTabs
                tabNames={tabNames}
                tabComponents={tabComponents}
                value={validatedActiveTab}
                onTabChange={setActiveTab}
            />
        </Box>
    );
};

export default EventTable;
