import React, { useState}  from 'react';
import { VicuTabBar } from './VicuTabBar'; // Adjust the import path as necessary
import { Device } from "../../interfaces/Device";
import { VICU } from "../../interfaces/VICU";
import { useTranslation } from "react-i18next";
import { NEWSTable } from "../../pages/Home/NEWS/NEWSTable";
import CustomTabs from "../tabs/CustomTabs";
import { CCMCentral } from "../../pages/Home/CCMCentral";

interface RPMDashboardTabsProps {
  visibleDevices: Device[];
  monitorGroupList: VICU[];
  setSelectedVICU: (vicu: VICU | null) => void;
  selectedVICU: VICU | null;
  updateDeviceList: () => void;
  setDevices: (devices: Device[]) => void;
  useSyspool: boolean;
  organization_id: string;

  regionOptions: { value: string; label: string }[]; // Add the dropdownOptions prop
  regionDropdownTitle: string;
  selectedRegionItem: string | undefined;
  onRegionChange: (value: string) => void; // Add this prop

  filterOptions: { value: number; label: string }[]; // Add the dropdownOptions prop
  filterDropdownTitle: string;
  selectedFilterItem: number | undefined;
  onFilterChange: (value: number) => void; // Add this prop


}

const RPMDashboardTabs: React.FC<RPMDashboardTabsProps> = ({
  organization_id,
  visibleDevices, monitorGroupList, setSelectedVICU, selectedVICU, updateDeviceList, setDevices, useSyspool,
  regionOptions,
  onRegionChange,
  regionDropdownTitle,
  selectedRegionItem,
  filterOptions,
  onFilterChange,
  filterDropdownTitle,
  selectedFilterItem

}) => {

    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [selectedRegion, setSelectedRegion] = useState<string>(
        selectedRegionItem || (regionOptions?.length > 0 ? regionOptions[0].value : '')
    );
    const [selectedFilter, setSelectedFilter] = useState<number>(selectedFilterItem || 0);

    // Create the base arrays
    const tabNames = [
		t("ccm_central"),
        t("news_dashboard"),
    ];

    const tabComponents = [
		<CCMCentral organization_id={organization_id} devicelist={visibleDevices} filterValue={selectedFilter}>
		<VicuTabBar
			vicuList={monitorGroupList?.filter((item: any) => item.group_name !== "syspool")}
			activateVicu={setSelectedVICU}
			activeVicu={selectedVICU}
			refreshDevices={updateDeviceList}
		/>
	</CCMCentral>,

		<NEWSTable devicelist={visibleDevices}>
			<VicuTabBar
				vicuList={monitorGroupList?.filter((item: any) => item.group_name !== "syspool")}
				activateVicu={setSelectedVICU}
				activeVicu={selectedVICU}
				refreshDevices={updateDeviceList}
			/>
		</NEWSTable>
    ];

    const handleRegionChange = (value: string) => {
        setSelectedRegion(value);
        onRegionChange(value);
    };
    const handleFilterChange = (value: string) => {
        setSelectedFilter(Number (value));
        onFilterChange(Number (value));
    };


    return (
		<div>
			<CustomTabs 
				tabNames={tabNames} 
				tabComponents={tabComponents} value={currentTab}
                onTabChange={setCurrentTab}

				regionOptions={regionOptions} // Pass dropdownOptions to CustomTabs
                selectedRegion={selectedRegion}
                onRegionChange={handleRegionChange}
                regionDropdownTitle={regionDropdownTitle}
	
				filterOptions={filterOptions} // Pass dropdownOptions to CustomTabs
                selectedFilter={'' + selectedFilter}
                onFilterChange={handleFilterChange}
                filterDropdownTitle={filterDropdownTitle}

			/>
		</div>
	);

};

export default RPMDashboardTabs;