class IdleTimer {
  constructor({ timeout, onTimeout, activityCheck }) {
      this.timeout = timeout * 1000; // Convert seconds to milliseconds
      this.onTimeout = onTimeout;
      this.activityCheck = activityCheck;
      this.interval = null;
      this.inactivityStart = Date.now(); // Track when inactivity starts
      this.start();
  }

  start() {
      //console.log("IdleTimer started");
      this.inactivityStart = Date.now(); // Reset inactivity start time

      this.interval = setInterval(() => {
          if (this.activityCheck()) {
              //console.log("Activity detected: Resetting inactivity start time");
              this.inactivityStart = Date.now(); // Reset inactivity timer
          } else {
              const elapsed = Date.now() - this.inactivityStart;
              //console.log(`No activity for ${Math.floor(elapsed / 1000)} seconds`);
              if (elapsed >= this.timeout) {
                  //console.log("Inactivity timeout reached: Triggering logout");
                  this.onTimeout();
                  this.cleanUp(); // Stop the timer
              }
          }
      }, 1000); // Check activity every second
  }

  cleanUp() {
      //console.log("Cleaning up IdleTimer");
      if (this.interval) {
          clearInterval(this.interval);
          this.interval = null;
      }
  }
}

export default IdleTimer;
