import { useEffect, useRef, useContext, useCallback } from "react";
import { useCookies } from "react-cookie";
import { UserContext } from "../context/UserContext";
import UserService from "../services/UserService";
import IdleTimer from "../hooks/IdleTimer";

const useMove = () => {
    const isMovingRef = useRef(false);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        const handleMouseMove = () => {
            isMovingRef.current = true;

            if (timer) clearTimeout(timer);

            timer = setTimeout(() => {
                isMovingRef.current = false;
            }, 1000); // Reset activity after 1 second of no movement
        };

        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            if (timer) clearTimeout(timer);
        };
    }, []);

    return isMovingRef;
};

export const useAutoLogout = () => {
    const [cookies, , removeCookie] = useCookies(["access_token", "refresh_token"]);
    const userCtx = useContext(UserContext);
    const isMovingRef = useMove();
    const idleTimerRef = useRef<IdleTimer | null>(null);

    const force_logout = useCallback(async () => {
        console.log("force_logout triggered: Logging out user...");
        try {
            await UserService.blacklistToken(cookies.refresh_token);
        } catch (error) {
            console.error("Failed to blacklist token:", error);
        } finally {
            removeCookie("access_token", { path: "/" });
            removeCookie("refresh_token", { path: "/" });
        }
    }, [cookies.refresh_token, removeCookie]);

    const timeoutInSeconds = userCtx?.user?.autoLogout ? parseInt(userCtx.user.autoLogout) * 60 : 300; // Default to 5 minutes

    useEffect(() => {
        if (cookies["access_token"] && userCtx?.user?.is_auto_logout_enabled) {
            if (!idleTimerRef.current) {
                idleTimerRef.current = new IdleTimer({
                    timeout: timeoutInSeconds,
                    onTimeout: force_logout,
                    activityCheck: () => {
                        const activityState = isMovingRef.current;
                        return activityState;
                    },
                });
            }

            return () => {
                if (idleTimerRef.current) {
                    idleTimerRef.current.cleanUp();
                    idleTimerRef.current = null;
                }
            };
        }
    }, [userCtx?.user, force_logout, cookies, isMovingRef, timeoutInSeconds]);

    return {};
};
