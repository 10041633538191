
// context/UserContext.tsx
import React, { createContext, useState, useMemo, ReactNode } from 'react';
import { User } from '../interfaces/user/User';

interface UserContextType {
  user: User | null;
  setUser: (user: User | null) => void;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  
  const value = useMemo(() => { return { user, setUser };}, [user]);
  
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};



/*
import { createContext, Dispatch, SetStateAction, useState } from "react";
import { User } from "../interfaces/user/User";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import * as CONSTANTS from "../utils/constants/constants";
import { useCookies } from "react-cookie";

interface UserCtx {
    user?: User;
    setUser: Dispatch<SetStateAction<User>>;
}

export const UserContext = createContext<UserCtx | null>(null);




export default function UserProvider({ children }: any) {
    const [user, setUser] = useState<User>(
        // @ts-ignore
        JSON.parse(localStorage.getItem("user")) || null
    );
    const [, setCookie, removeCookie] = useCookies(["access_token", "refresh_token",]);

    axios.interceptors.response.use(
        (config) => {
            if (!config.headers.authorization && config.status === 401) {
                config.headers.authorization = `Bearer ${user?.token?.access}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const refreshAuthLogic = (failedRequest: any) => {
        const data = {
            refresh: user?.token?.refresh,
        };

        return axios({
            method: "POST",
            data,
            url: CONSTANTS.HOST + "api/o/token/refresh/",

        }).then(async (tokenRefreshResponse) => {
            const new_access_token = tokenRefreshResponse.data.access;
                // console.log ("New access token:", new_access_token)
                failedRequest.response.config.headers.authorization =
                    "Bearer " + new_access_token;

                    // Save to cookie
                    // @ts-ignore
                    setCookie ("access_token", new_access_token);

                // Save to local storage 
                const item = {
                    ...user,
                    token: {
                        ...user!.token!,
                        access: new_access_token,
                    },
                }
                localStorage.setItem(
                    "user",
                    JSON.stringify (item)
                );

                // Set in user context
                setUser (item);

                return Promise.resolve();
            })
            .catch((e) => {
                //setUser(null);
                removeCookie("access_token");
                removeCookie("refresh_token");
            });
    };

    const userContext = { user, setUser };
    createAuthRefreshInterceptor(axios, refreshAuthLogic);

    return (
        <UserContext.Provider value={userContext}>
            {children}
        </UserContext.Provider>
    );
}


*/